// by using universal-cookie, you can expect that this code will only work
// client-side. to make it work server-side, have your component pass the
// cookies object provided to it by the `withCookies` HOC.
import Cookies from "universal-cookie";
import { compose } from "redux";
import { createAction } from "redux-actions";
import _ from "underscore";
import $ from "clients/RawClient";

import ActionTypes from "./action-types.js";
import HTTP_HEADERS from "rtr-constants/http-headers";
import objectToQueryString from "../modules/object-to-query-string";
import filterHelpers from "../helpers/filter-helpers";
import * as Constants from "rtr-constants";
import MembershipHelpers from "helpers/membership-helpers";
import AvailabilityFilterHelpers from "helpers/availability-filter-helpers";
import { Product } from "@rtr/godmother";
import carouselTypes from "helpers/carousel-types";
import { membershipLens, inventoryEligibilities } from "rtr-constants";
import HeapHelpers from "helpers/heap-helpers";
import {
  generateQueryString,
  mapWorkingFiltersToGridQuery,
  removeFilter,
  setFilter,
} from "helpers/disco-filter-helpers";
import { isStorefrontNext } from "helpers/environment-helpers.js";
import { addQueryParam, getLocationPathname, getLocationSearch } from "helpers/location-helpers.js";
import { DiscoSearchServiceClient } from "clients/DiscoSearchServiceClient.js";

const MEMBERSHIP_NAME_CLASSIC = "classic";
const MEMBERSHIP_NAME_UNLIMITED = "unlimited";
const MEMBERSHIP_NAME_RTRUPDATE = "rtrupdate";
const MEMBERSHIP_NAME_MEMBERSHIP = "membership";
const membershipNames = [
  MEMBERSHIP_NAME_CLASSIC,
  MEMBERSHIP_NAME_UNLIMITED,
  MEMBERSHIP_NAME_RTRUPDATE,
  MEMBERSHIP_NAME_MEMBERSHIP,
];

let currentRequest;
const PERSISTED_FILTERS = ["zip_code", "date", "duration", "canonicalSizes"];
const cookies = new Cookies();

const actions = {
  addRemainingProducts: createAction(ActionTypes.ADD_REMAINING_PRODUCTS),
  changeCategory: createAction(ActionTypes.CHANGE_CATEGORY),
  changeFilters: createAction(ActionTypes.CHANGE_FILTERS),
  changePage: createAction(ActionTypes.CHANGE_PAGE),
  changeSort: createAction(ActionTypes.CHANGE_SORT),
  collectionCarouselSuccess: createAction(ActionTypes.COLLECTION_CAROUSEL_SUCCESS),
  midGridContentFailure: createAction(ActionTypes.MID_GRID_CONTENT_FAILURE),
  midGridContentLoading: createAction(ActionTypes.MID_GRID_CONTENT_LOADING),
  midGridContentSuccess: createAction(ActionTypes.MID_GRID_CONTENT_SUCCESS),
  receiveProps: createAction(ActionTypes.RECEIVE_PROPS),
  recommendationCarouselSuccess: createAction(ActionTypes.RECOMMENDATION_CAROUSEL_SUCCESS),
  refreshGridProduct: createAction(ActionTypes.REFRESH_GRID_PRODUCT),
  rentTheLookLoading: createAction(ActionTypes.RENT_THE_LOOK_LOADING),
  rentTheLookSuccess: createAction(ActionTypes.RENT_THE_LOOK_SUCCESS),
  setAddressId: createAction(ActionTypes.SET_ADDRESS_ID),
  setCurrentProduct: createAction(ActionTypes.SET_PRODUCT),
  setFiltersOpen: createAction(ActionTypes.SET_FILTERS_OPEN),
  setLoading: createAction(ActionTypes.LOADING),
  similarStylesLoading: createAction(ActionTypes.SIMILAR_STYLES_LOADING),
  similarStylesSuccess: createAction(ActionTypes.SIMILAR_STYLES_SUCCESS),
  toggleOpenFilters: createAction(ActionTypes.TOGGLE_OPEN_FILTERS),
  triggerError: createAction(ActionTypes.TRIGGER_ERROR),
  updateCurrentPage: createAction(ActionTypes.UPDATE_CURRENT_PAGE),
  updateMembershipLens: createAction(ActionTypes.UPDATE_MEMBERSHIP_LENS),
  youMayAlsoLikeLoading: createAction(ActionTypes.YOU_MAY_ALSO_LIKE_LOADING),
  youMayAlsoLikeSuccess: createAction(ActionTypes.YOU_MAY_ALSO_LIKE_SUCCESS),

  applyFilters: function (selectedFilters, filterKey, options) {
    return function (dispatch) {
      if (_.isEmpty(selectedFilters[filterKey])) {
        dispatch(actions.clearFilters([filterKey], options));
      } else {
        dispatch(actions.changeFilters(selectedFilters));
        dispatch(actions.submitFilters(options));
      }
    };
  },

  deregisterEventSelectionAndApplyFilters: function (eventId, selectedFilters, filterKey, options) {
    return function (dispatch) {
      $.ajax({
        url: "/events/" + eventId + "/register_event_selection",
        type: "DELETE",
      }).then(function () {
        dispatch(actions.applyFilters(selectedFilters, filterKey, options));
      });
    };
  },

  submitFilters: function (options = {}) {
    return function (dispatch, getState) {
      const errors = {};
      const state = getState();
      const changedFilters = filterHelpers.getDiff(state.workingFilters, state.filters);
      let filters = state.workingFilters;

      // Don't re-submit if I haven't edited anything
      if (!_.isEmpty(state.workingFilters) && _.isEmpty(changedFilters)) {
        return Promise.reject();
      }

      // Clean and validate any changed availability filters
      if (_.intersection(changedFilters, ["date", "zip_code", "duration"]).length) {
        filters = filterHelpers.cleanAvailabilityFilters(filters);
      }

      // Remove maternity filters if we are on a carousel type grid or clearance grid or accessory grid
      const hierarchy = _.last(state.hierarchy);
      const isAccessoryGrid = hierarchy?.id === "accessory";
      const isPersonalizedGrid = state?.gridView?.isPersonalizedGrid ?? false;
      const isClearanceGrid = state?.gridView?.isClearance ?? false;
      const gridsToExcludeFromMaternity = isPersonalizedGrid || isClearanceGrid || isAccessoryGrid;
      if (gridsToExcludeFromMaternity && filters.maternity) {
        filters = _.omit(filters, Constants.filters.maternity);
      }

      dispatch(actions.triggerError(errors));
      // If there are errors, don't submit date and zip_code
      // This ensures that *other* filters (e.g. formality) can
      // be submitted even if zip/date are not valid.
      if (!_.isEmpty(errors)) {
        filters = filterHelpers.removeAvailabilityFilters(filters);
      }

      const params = { filters: filters, sort: state.sort };
      /*
      When a user has reached the dead end page from a text search, then applies an additional filter
      to the empty result set, this parameter will get added to the query string. this is done to preserve
      this particular state for what to display on the dead end search page.
      In the case of a dead end text search, a carousel is shown and includes a link to the designers page.
      This view should persist as long as the user remains on that dead end page, regardless of how many filters
      are applied afterwards.
      However, if the user searches for something valid (e.g. "red dress"), then applies too many filters
      and gets the dead end page, we don't want to show them the link to the designers page or the carousel.
      so this adds deadEnd=1 to the query string, allowing me to maintain the state of the dead end text
      search experience even as additional filters are applied.
       */
      if (options.isDeadEndTextSearch) {
        params.deadEnd = 1;
      }

      /* Ensure buynow experience is maintained when filtering */
      if (state?.isBuyNow) {
        params.buynow = true;
      }

      if (state.queryParams && typeof state.queryParams === "object") {
        for (const param in state.queryParams) {
          if (!Object.prototype.hasOwnProperty.call(params, param)) {
            params[param] = state.queryParams[param];
          }
        }
      }

      // to stop redirecting to /pages/designer
      const path =
        state.path && typeof state.path === "string" && state.path?.startsWith("/pages/designers")
          ? state.path?.replace("/pages/designers", "/designers")
          : state.path;
      const pathForNavigate = addQueryParam(path, objectToQueryString(params));

      dispatch(actions.changePage(1));
      return dispatch(actions.navigate(pathForNavigate));
    };
  },

  replaceGridUrlPath: function (page) {
    return function (dispatch, getState) {
      // const errors = {};
      const state = getState();
      const excludedFilters = ["date", "duration"];
      const changedFilters = filterHelpers
        .getDiff(state.workingFilters, state.filters)
        .filter(filterKey => !excludedFilters.includes(filterKey));

      // If nothing has changed do nothing
      if (!changedFilters) {
        return;
      }

      const queryString = compose(
        generateQueryString,
        removeFilter("depotId"), // backend uses membershipDepotId cookie
        removeFilter("includeEarlyAccess"), // backend derives from membershipState (if applicable)
        removeFilter("programEligibility"), // backend derives from membershipState (if applicable)
        setFilter("page", page),
        setFilter("sort", state.sort || state.workingSort),
        mapWorkingFiltersToGridQuery
      )(state.workingFilters || {});
      window.history.replaceState(null, null, `?${queryString}`);
      dispatch(actions.updateCurrentPage(page));
    };
  },

  // Note: we need no side-effects from this action as all we care about
  // is the set-cookie header we get back from the back-end
  refreshMembershipDepotIdCookie: function (zipCode, callbackFn) {
    return function () {
      $.post("/api/preferences/zip-code", { zipCode: zipCode }).then(({ depotId }) => {
        callbackFn({
          zip_code: zipCode,
          depotId,
        });
      });
    };
  },

  submitShortlistFilters: function () {
    return function (dispatch, getState) {
      const errors = {};
      const state = getState();
      const changedFilters = filterHelpers.getDiff(state.workingFilters, state.filters);
      let filters = state.workingFilters;

      // Filters not re-submitted if no edits were made
      if (!_.isEmpty(state.workingFilters) && _.isEmpty(changedFilters)) {
        return Promise.reject();
      }

      // Clean and validate any changed availability filters
      if (_.intersection(changedFilters, ["date", "zip_code", "duration"]).length) {
        filters = filterHelpers.cleanAvailabilityFilters(filters);
        _.extend(errors, filterHelpers.validateAvailability(filters));
      }

      dispatch(actions.triggerError(errors));

      const params = { filters: filters, sort: state.sort, shortlist_grid_gm: true };
      const pathForNavigate = state.path + "?" + objectToQueryString(params);

      return dispatch(actions.navigate(pathForNavigate));
    };
  },

  setMembershipLens: function (lens, willNavigate = true, logToHeap = true) {
    // Not every time we setMembershipLens do we want to navigate to another url.
    // We'll default true for the many other places setMembershipLens is used.

    if (!membershipNames.includes(lens)) {
      return Promise.reject();
    }

    const buildPathForNavigate = state => {
      const filters = state.workingFilters;
      const params = { filters: filters, sort: state.sort };

      // preserving page placement in product grids
      const queryParams = new URLSearchParams(window.location.search);
      const preserveParam = "page";
      const paramValue = queryParams.get(preserveParam);
      if (paramValue) {
        params[preserveParam] = paramValue;
      }

      return state.path + "?" + objectToQueryString(params);
    };

    return function (dispatch, getState) {
      cookies.set("membership_lens", lens, { path: "/" });

      //Always update the event property in Heap, since noop updates are safe
      HeapHelpers.updateUserIntent(lens);

      //Only fire the explicit event if specified (i.e. this isn't being called on page load)
      if (logToHeap) {
        HeapHelpers.trackIntentChange(lens);
      }

      if (willNavigate) {
        dispatch(actions.navigate(buildPathForNavigate(getState())));
      }
      dispatch(actions.updateMembershipLens(lens));
    };
  },

  setMembershipLensFromCookie: function (userData = {}) {
    return function (dispatch) {
      const lensCookie = cookies.get("membership_lens");
      const isValidLensType = Object.values(membershipLens).includes(lensCookie);
      const reduxLens = MembershipHelpers.getUserDataLens(userData);

      if (lensCookie && isValidLensType && reduxLens !== lensCookie) {
        dispatch(actions.updateMembershipLens(lensCookie));
      }
    };
  },

  setMembershipLensOnPageLoad: function (userData = {}) {
    return function (dispatch) {
      if (window) {
        // First check if a lens was specifically passed as a URL param
        const searchParams = new URLSearchParams(window?.location?.search);
        const lensParam = searchParams.get("lens");
        const lens = lensParam ? lensParam : cookies.get("membership_lens");
        const isValidLensType = Object.values(membershipLens).includes(lens);

        if (lens && isValidLensType) {
          //We always want to track this in Heap, even if lens is consistent between the cookie, Redux, and the query param
          //This is because these _should_ be consistent, and may have changed from the previous page
          //This does mean we may have false positives when the query matches the existing lens/intent from the previous page
          HeapHelpers.trackIntentChange(lens);

          const lensCookie = cookies.get("membership_lens");
          // Sometimes the ruby layer sets the cookie but fails to set the initial redux state
          const reduxLens = MembershipHelpers.getUserDataLens(userData);

          if (lensCookie !== lens) {
            dispatch(actions.setMembershipLens(lens, false, false));
          } else if (reduxLens !== lens) {
            dispatch(actions.updateMembershipLens(lens));
          }
        } else {
          // If no URL param, check if a lens exists in cookie
          dispatch(actions.setMembershipLensFromCookie(userData));
        }
      }
    };
  },

  setDefaultMembershipLensForSubscriber: function (membershipState = {}) {
    return function (dispatch, getState) {
      const lensAlreadySet = getState().userData?.lens;

      if (!MembershipHelpers.isActiveMembership(membershipState) || lensAlreadySet) {
        return;
      }

      if (MembershipHelpers.hasInventoryEligibility(membershipState, inventoryEligibilities.rtr_update)) {
        dispatch(actions.updateMembershipLens(membershipLens.RTRUpdate));
      } else if (MembershipHelpers.hasInventoryEligibility(membershipState, inventoryEligibilities.rtr_unlimited)) {
        dispatch(actions.updateMembershipLens(membershipLens.unlimited));
      }
    };
  },

  reloadCurrentGrid: function (filters, path, sort, page = 1) {
    return function (dispatch) {
      const params = { filters: filters, sort: sort, page: page };
      const pathForNavigate = path + "?" + objectToQueryString(params);
      dispatch(actions.navigate(pathForNavigate));
    };
  },

  clearFilters: function (filterKeys, options) {
    return function (dispatch, getState) {
      // Using working filters to persist filters that have been
      // entered but not filtered-by yet: chiefly, zip_code.
      const state = getState();
      const newFilters = _.omit(state.workingFilters, filterKeys);

      dispatch(actions.clearPersistedHold(_.intersection(filterKeys, PERSISTED_FILTERS)));

      dispatch(actions.changeFilters(newFilters));
      return dispatch(actions.submitFilters(options));
    };
  },

  clearFiltersOnly: function (filterKeys) {
    return function (dispatch, getState) {
      const state = getState();
      const newFilters = _.omit(state.workingFilters, filterKeys);

      dispatch(actions.clearPersistedHold(_.intersection(filterKeys, PERSISTED_FILTERS)));

      return dispatch(actions.changeFilters(newFilters));
    };
  },

  clearPersistedHold: function (holdKeys) {
    return function () {
      const clearedHold = {};

      if (_.isEmpty(holdKeys)) {
        return;
      }

      _.each(holdKeys, function (key) {
        clearedHold[key] = "";
      });

      return $.ajax({
        type: "PUT",
        url: "/hold_preferences/" + cookies.get("RTR_ID"),
        data: { hold: clearedHold },
      });
    };
  },

  // We fetch just the first few products server-side (12 at the time of writing
  // this comment). This reduces initial page load times while keeping the first
  // few screenfuls totally functional. Once we're done loading, we request the
  // rest of the products for this page.
  fetchRemainingProducts: function () {
    return function (dispatch) {
      let path = getLocationPathname() + getLocationSearch();

      // Take our current path and assign the "remainder" preset. Our controller
      // knows that this means we only want a slice of our state object.
      path += _.include(path, "?") ? "&" : "?";
      path += "preset=remainder";

      $.ajax({ url: path, headers: { Accept: "application/json" } }).then(function (data) {
        // We use a new action here, instead of the traditional RECEIVE_PROPS or,
        // even RECEIVE_PARTIAL_PROPS, because our data does not include the first
        // batch of products we loaded!
        dispatch(actions.addRemainingProducts(data));
      });
    };
  },

  fetchRentTheLook(productId) {
    return dispatch => {
      if (!productId) {
        console.log("Product ID required to load Rent the Look outfits");
      } else {
        dispatch(actions.rentTheLookLoading(true));
        Product.list({
          filter: {
            outfits: productId,
          },
          fields: {
            product: "displayName,canonicalSizes,images,designer.displayName,urlHistory",
          },
          sort: "explicit",
          include: "skus",
        })
          .then(data => {
            if (Array.isArray(data) && data.length) {
              dispatch(
                actions.rentTheLookSuccess({
                  title: "Rent the Look",
                  pixelName: "rent_the_look",
                  type: carouselTypes.OUTFITS,
                  products: data,
                })
              );
            }
          })
          .catch(e => {
            console.log("Failed to load Rent the Look outfits " + JSON.stringify({ error: e?.message }));
          })
          .finally(() => {
            dispatch(actions.rentTheLookLoading(false));
          });
      }
    };
  },

  fetchSimilarStyles(productId, userData, workingFilters) {
    return (dispatch, getState) => {
      if (!productId) return null;
      const state = getState();
      const filterStyles = {
        visuallySimilarTo: productId,
        categories: "rentable",
        [getMinAvailabilityKey(state.isBuyNow, userData)]: getMinAvailabilityValue(
          state.isBuyNow,
          userData,
          workingFilters
        ),
      };

      dispatch(actions.similarStylesLoading(true));

      Product.list(
        {
          filter: !workingFilters?.canonicalSizes?.length
            ? filterStyles
            : { ...filterStyles, ["products.canonicalSizes"]: workingFilters?.canonicalSizes?.toString() },
          fields: {
            product: "retailPrice,displayName,canonicalSizes,images,designer.displayName,urlHistory",
          },
          sort: "explicit",
          include: `skus,skus.${MembershipHelpers.getUserDataLensForAvailability(userData)}Availabilities,price`,
        },
        this.getProductListOptions(state)
      )
        .then(data => {
          if (Array.isArray(data) && data.length) {
            dispatch(
              actions.similarStylesSuccess({
                title: "Similar Styles",
                pixelName: "similar_items",
                subtitle: "View All",
                type: carouselTypes.VISUALLY_SIMILAR_TO,
                products: data,
                url: `/pdp/shop/${productId.toLowerCase()}_similar_styles/products`,
              })
            );
          }
        })
        .catch(() => {
          // we are removing log as we are working on an aligned logging standard for gotdmother.ts calls -- AS 6/5/23
          /* noop */
        })
        .finally(() => {
          dispatch(actions.similarStylesLoading(false));
        });
    };
  },

  filtersForYouMayAlsoLike(productId, userData, workingFilters, isMinAvailabilityRequired = false) {
    const { getUserDataLensForAvailability } = MembershipHelpers;

    let filters = {
      productsSimilarTo: productId,
      categories: "rentable",
      [getMinAvailabilityKey(isMinAvailabilityRequired, userData)]:
        getMinAvailabilityValue(isMinAvailabilityRequired, userData, workingFilters) ?? 1, // adding fallBack case to 1 as undefined is flowing for reserve intent
      daysPosted: 1,
    };

    if (workingFilters?.canonicalSizes?.length) {
      filters = { ...filters, ["products.canonicalSizes"]: workingFilters?.canonicalSizes?.toString() };
    }

    if (!getUserDataLensForAvailability(userData) === Constants.membershipLensForAvailability.classic) {
      filters = { ...filters, eligibleFor: getUserDataLensForAvailability(userData) };
    }

    // only with reserve intent change daysPosted to 0
    // isMinAvailabilityRequired maps isBuyNow state in whch case we dont want to override daysPosted
    if (
      !isMinAvailabilityRequired &&
      getUserDataLensForAvailability(userData) === Constants.membershipLensForAvailability.classic
    ) {
      filters = { ...filters, daysPosted: 0 };
    }

    return filters;
  },

  fetchYouMayAlsoLike(productId, userData, workingFilters) {
    return (dispatch, getState) => {
      if (!productId) return null;

      const state = getState();
      dispatch(actions.youMayAlsoLikeLoading(true));

      Product.list(
        {
          page: {
            offset: 0,
            limit: 20,
          },
          filter: actions.filtersForYouMayAlsoLike(productId, userData, workingFilters, state.isBuyNow),
          fields: {
            product: "retailPrice,displayName,canonicalSizes,images,designer.displayName,urlHistory",
          },
          sort: "explicit",
          include: `skus,skus.${MembershipHelpers.getUserDataLensForAvailability(userData)}Availabilities,price`,
        },
        this.getProductListOptions(state)
      )
        .then(data => {
          if (Array.isArray(data) && data.length) {
            dispatch(
              actions.youMayAlsoLikeSuccess({
                title: "You may also like",
                pixelName: "you_may_also_like",
                subtitle: "View All",
                type: carouselTypes.SIMILAR_ITEMS,
                url: `/similar/${productId}/products`,
                products: data,
              })
            );
          }
        })
        .catch(() => {
          // we are removing log as we are working on an aligned logging standard for gotdmother.ts calls -- AS 6/5/23
          /* noop */
        })
        .finally(() => {
          dispatch(actions.youMayAlsoLikeLoading(false));
        });
    };
  },

  fetchCollectionCarousel(collectionId, category) {
    return (dispatch, getState) => {
      const lens = getState().userData?.lens;

      const query = { curationId: [collectionId] };

      if (category) {
        query.category = [category];
      }

      DiscoSearchServiceClient.getInstance()
        .curationSearch(query, 0, 40, lens)
        .then(data => {
          const gridUrl = `/shop/${collectionId}/products`;

          dispatch(
            actions.collectionCarouselSuccess({
              title: "New Arrivals",
              subtitle: "View All",
              pixelName: "new_arrivals",
              type: carouselTypes.COLLECTION,
              products: data.items,
              url: gridUrl,
            })
          );
        })
        .catch(err => {
          console.log("Error fetching Collections Carousel: ", err);
        });
    };
  },

  fetchRecommendationCarousel(query) {
    return function (dispatch) {
      DiscoSearchServiceClient.getInstance()
        .recommendationSearch(query, 0, 40)
        .then(data => {
          const { products, title } = data.recommendations[0];
          dispatch(
            actions.recommendationCarouselSuccess({
              title: "More " + title + " like this",
              pixelName: "recommendations",
              type: carouselTypes.RECOMMENDATION,
              products: products,
            })
          );
        })
        .catch(err => {
          console.log("Error fetching Collections Carousel: ", err);
        });
    };
  },

  // For Next we don't have the GodmotherRequestContext for our default headers, instead we check
  // redux for predictedZip and include it when fetching products for carousels. Without zip we get
  // an empty response.
  // Note: at the time of writing, setting headers like this _replaces_ the default headers value (if set)
  // in assets/javascripts/config/godmother.js (see Base.getOptions in godmother.ts)
  getProductListOptions(state) {
    if (isStorefrontNext()) {
      const headers = {};

      const userId = state.userData?.authData?.id;
      if (userId) headers[HTTP_HEADERS.X_RTR_USER_ID] = userId;

      const zip = state.workingFilters?.zip_code || state.predictedZip;
      if (zip) headers[HTTP_HEADERS.X_RTR_ZIP_CODES] = zip;

      return {
        headers: headers,
      };
    }
    return {};
  },

  // TODO: figure out how to use
  // `reservationFormActions.updateProductAvailabilityForCarousels` instead, to
  // have a single action to update Grids, Carousels, and the ProductDrawer.
  refreshGridProductAvailability(styleName, baseUrl) {
    return function (dispatch) {
      // Strip `filters[searchText]` out of query params as this is consumed by Signal-Based Routing upstream which can result in no results.
      // See https://renttherunway.jira.com/wiki/spaces/~754213903/pages/4151410709/INCIDENT-503+-+Drawer+shows+NO+availability+when+actually+available
      const search = new URLSearchParams(baseUrl.search);
      search.delete("filters[searchText]");

      let path = `${baseUrl.pathname}?${search.toString()}`;

      // Take our current path and assign the "remainder" preset. Our controller
      // knows that this means we only want a slice of our state object.
      path += _.include(path, "?") ? "&" : "?";
      path += `filters[styleNames][]=${encodeURIComponent(styleName)}`;
      path = path.replace(/[?&]page=\d+/, "");

      $.ajax({ url: path, headers: { Accept: "application/json" } }).then(function (data) {
        // We use a new action here, instead of the traditional RECEIVE_PROPS
        // or, even RECEIVE_PARTIAL_PROPS, because our data does not include the
        // first batch of products we loaded!
        dispatch(actions.refreshGridProduct({ styleName, data }));
      });
    };
  },

  navigate: function (newPath) {
    return function (dispatch, getState) {
      const path = newPath || getState().path;

      dispatch(actions.setLoading(true));
      const controller = new AbortController();
      const signal = controller.signal;

      if (currentRequest) {
        controller.abort();
      }

      currentRequest = $.ajax({
        type: "GET",
        url: path,
        headers: { Accept: "application/json" },
        cache: false,
        signal,
      })
        .then(
          function (data) {
            if (window.HISTORY_STATE) {
              const previousState = getState();
              const nextState = _.extend({}, previousState, data);
              HISTORY_STATE.replaceState(nextState, "", newPath);
            }
          },
          function (jqXHR) {
            if (jqXHR.status === 401) {
              // If we're hitting this block, then that means we've made an AJAX
              // request on a grid with a protected route, i.e. the user must be
              // authorized or identified to see this grid. At this point, we're
              // going to reload the page so that the user is redirected through the
              // regular authentication flow.
              window.location.reload(true);
            }
          }
        )
        .finally(function () {
          currentRequest = null;
        });

      return currentRequest;
    };
  },

  navigateToCategory: function (path, hierarchy) {
    return function (dispatch) {
      dispatch(actions.changeCategory(hierarchy));
      // we should optimistically clear filters here, but this action has
      // changed and now creates a request. TODO add an action that removes
      // all filters but does not make a request (because in this case the
      // request comes later)
      // dispatch(actions.clearFilters());
      dispatch(actions.changePage(1));
      return dispatch(actions.navigate(path));
    };
  },

  navigateToPage: function (path, page) {
    return function (dispatch) {
      window.scrollTo(0, 0);
      dispatch(actions.changePage(page));
      return dispatch(actions.navigate(path));
    };
  },

  navigateToSort: function (path, sort) {
    return function (dispatch) {
      dispatch(actions.changeSort(sort));
      if (isStorefrontNext()) {
        return;
      }
      return dispatch(actions.navigate(path));
    };
  },

  fetchMidGridContent: function (path) {
    return function (dispatch) {
      dispatch(actions.midGridContentLoading(true));

      const url = `/products/mid_grid_content?path=${path}`;

      $.ajax({
        url,
        headers: {
          Accept: "application/json",
        },
      })
        .then(
          res => {
            dispatch(actions.midGridContentSuccess(res));
          },
          () => {
            dispatch(actions.midGridContentFailure(true));
          }
        )
        .finally(() => {
          dispatch(actions.midGridContentLoading(false));
        });
    };
  },
};

const getMinAvailabilityKey = (isMinAvailabilityRequired, userData) => {
  return isMinAvailabilityRequired
    ? `skus.unlimitedAvailabilities.minAvailability`
    : `skus.${MembershipHelpers.getUserDataLensForAvailability(userData)}Availabilities.minAvailability`;
};

const getMinAvailabilityValue = (isMinAvailabilityRequired, userData, workingFilters) => {
  return isMinAvailabilityRequired ? 1 : AvailabilityFilterHelpers.membershipMinAvailability(userData, workingFilters);
};

export default actions;

export const {
  addRemainingProducts,
  applyFilters,
  changeCategory,
  changeFilters,
  changePage,
  changeSort,
  clearFilters,
  clearFiltersOnly,
  clearPersistedHold,
  collectionCarouselSuccess,
  deregisterEventSelectionAndApplyFilters,
  fetchMidGridContent,
  fetchRecommendationCarousel,
  fetchRemainingProducts,
  navigate,
  navigateToCategory,
  navigateToPage,
  navigateToSort,
  receiveProps,
  recommendationCarouselSuccess,
  refreshGridProduct,
  refreshGridProductAvailability,
  refreshMembershipDepotIdCookie,
  reloadCurrentGrid,
  rentTheLookLoading,
  rentTheLookSuccess,
  replaceGridUrlPath,
  setFiltersOpen,
  setLoading,
  setMembershipLens,
  submitFilters,
  submitShortlistFilters,
  toggleOpenFilters,
  triggerError,
  updateMembershipLens,
  setAddressId,
} = actions;
