var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"cCGo0tWoOKBj9KeINbT_P"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from "@sentry/nextjs";
import { getEnvironmentName } from "helpers/environment-helpers";
import SentryCommonConfig from "./sentry.common.config";
import { isSSR } from "./assets/javascripts/helpers/client-server-helper";

const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN;
// TODO: Redux integration, how to with Standard SF using CDN?
Sentry.init({
  ...SentryCommonConfig,
  dsn: SENTRY_DSN || "https://e7f15dbc86b74d48a3ae06f6a443d6f9@o117543.ingest.sentry.io/6652644",
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 0.0,
  environment: getEnvironmentName(),
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});

// save Sentry to the window object so we don't need to import it into
// files that are used in both SF.Next and SF.Ruby
if (!isSSR()) {
  window.Sentry = window.Sentry || Sentry;
}
